import { userApi } from 'api/user';
import { locationHelpers } from 'utils/helpers/locationHelpers';
import { create } from 'zustand';

import type { IUser } from 'models/User/user';

export interface IUserState {
    isLoading: boolean;
    user: IUser | null;
    getUserInfo: () => Promise<IUser>;
    logout: () => Promise<void>;
    changePassword: () => Promise<void>;
}

export const useUser = create<IUserState>((set) => ({
    user: null,
    isLoading: false,

    getUserInfo: async () => {
        set({ isLoading: true });

        try {
            const user = await userApi.getUserInfo();

            set({ user });

            return user;
        } catch (e) {
            throw e;
        } finally {
            set({ isLoading: false });
        }
    },
    logout: async () => {
        try {
            await userApi.logout();
        } catch (e) {
            throw e;
        }
    },
    changePassword: async () => {
        set((state) => ({ ...state, isLoading: true }));

        try {
            const { link } = await userApi.changePassword();

            locationHelpers.replaceLocation(link);
        } catch (e) {
            throw e;
        } finally {
            set((state) => ({ ...state, isLoading: false }));
        }
    },
}));
