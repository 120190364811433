import { useEffect, useState } from 'react';
import type { DatePickerProps } from 'antd';
import { Button, DatePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { historySettlementsTableFiltersSelectors } from 'store/merchants/historySettlementsTableFiltersSelectors';
import { historySettlementsTableSelectors } from 'store/merchants/historySettlementsTableSelectors';
import { merchantsSelectors } from 'store/merchants/merchantsSelectors';
import { useHistorySettlementsTable } from 'store/merchants/useHistorySettlementsTable';
import { useHistorySettlementsTableFilters } from 'store/merchants/useHistorySettlementsTableFilters';
import { useMerchants } from 'store/merchants/useMerchants';
import MultiSelect from 'ui/controls/MultiSelect';
import { dateHelpers } from 'utils/helpers/dateHelpers';
import { predicates } from 'utils/helpers/predicates';
import type { TComponent } from 'utils/types/component';

import omitBy from 'lodash/omitBy';

import styles from './Filters.module.scss';
import { filtersHelpers } from './helpers';
import type { IFilterForm } from './types';

const INITIAL_FILTERS: IFilterForm = {
    startDate: '',
    endDate: '',
    currencyCodes: [],
    types: [],
};

export const Filters: TComponent = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm<IFilterForm>();
    const [formData, setFormData] = useState<IFilterForm>(INITIAL_FILTERS);

    const params = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getParams,
    );
    const clearBody = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.clearBody,
    );
    const setBody = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.setBody,
    );
    const getTypesOptions = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getTypesOptions,
    );
    const setParams = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.setParams,
    );
    const getMany = useHistorySettlementsTable(
        historySettlementsTableSelectors.getTable,
    );
    const currenciesOptions = useMerchants(
        merchantsSelectors.currenciesOptions,
    );

    useEffect(() => {
        return () => {
            form.resetFields();

            setFormData(INITIAL_FILTERS);

            clearBody();
        };
    }, []);

    const initStartDate = formData.startDate
        ? dateHelpers.formatDays(formData.startDate)
        : null;
    const initEndDate = formData.endDate
        ? dateHelpers.formatDays(formData.endDate)
        : null;

    const typeOptions = getTypesOptions(t);

    const handleClearFilters = () => {
        form.resetFields();

        setFormData(INITIAL_FILTERS);

        clearBody();

        getMany({}, params);
    };

    const onSubmit = () => {
        const enhanceData = omitBy(
            filtersHelpers.getFiltersData(formData),
            (field) =>
                predicates.isUndefined(field) ||
                predicates.isEmptyString(field) ||
                predicates.isEmptyArray(field),
        );

        const newParams = { ...params, page: 0 };

        setBody(enhanceData);
        setParams(newParams);

        getMany(enhanceData, newParams);
    };

    const handleChangeStartDate: DatePickerProps['onChange'] = async (
        _,
        startDate,
    ) => {
        setFormData((data) => ({
            ...data,
            startDate,
        }));
    };

    const handleChangeEndDate: DatePickerProps['onChange'] = async (
        _,
        endDate,
    ) => {
        setFormData((data) => ({
            ...data,
            endDate,
        }));
    };

    const handleChangeCurrency = (
        currencyCodes: IFilterForm['currencyCodes'],
    ) => {
        setFormData((data) => ({
            ...data,
            currencyCodes,
        }));
    };

    const handleChangeType = (types: IFilterForm['types']) => {
        setFormData((data) => ({
            ...data,
            types,
        }));
    };

    return (
        <Form form={form} onFinish={onSubmit} className={styles.leftControls}>
            <div className={styles.controlsContainer}>
                <MultiSelect
                    value={formData.currencyCodes}
                    onChange={handleChangeCurrency}
                    placeholder={t('common.form.currency')}
                    options={currenciesOptions}
                    maxTagCount='responsive'
                    className={styles.multiSelect}
                />
                <MultiSelect
                    value={formData.types}
                    onChange={handleChangeType}
                    placeholder={t('common.form.type')}
                    options={typeOptions}
                    maxTagCount='responsive'
                    className={styles.multiSelect}
                />
            </div>
            <div className={styles.controlsContainer}>
                <DatePicker
                    showTime
                    value={initStartDate}
                    onChange={handleChangeStartDate}
                    placeholder={t('common.form.dateFrom')}
                    className={styles.datePicker}
                />

                <DatePicker
                    showTime
                    value={initEndDate}
                    onChange={handleChangeEndDate}
                    placeholder={t('common.form.dateTo')}
                    className={styles.datePicker}
                />
            </div>
            <div className={styles.controlsContainer}>
                <Button type='default' onClick={handleClearFilters}>
                    {t('common.buttons.clear')}
                </Button>
                <Button type='primary' htmlType='submit'>
                    {t('common.buttons.apply')}
                </Button>
            </div>
        </Form>
    );
};
