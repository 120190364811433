import type { TComponent } from 'utils/types/component';

interface ICommissionCellProps {
    amount: number;
    currency: string;
}

const CommissionCell: TComponent<ICommissionCellProps> = (props) => {
    const { amount, currency } = props;

    return (
        <>
            {amount} {currency}
        </>
    );
};

export { CommissionCell };
