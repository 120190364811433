import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import { CommissionCell } from 'components/domains/Invoices/components/CommissionCell';

import type { IPayoutInvoice } from 'models/Invoice/Payout';
import type { TTableColumn } from 'models/Table';

import { ExternalIdentityCell } from './components/ExternalIdentityCell';
import { IdentityCell } from './components/IdentityCell';
import { Status } from './components/Status';
import { UserIdCell } from './components/UserIdCell';

export const COLUMNS: TTableColumn<IPayoutInvoice>[] = [
    {
        dataIndex: 'identity',
        key: 'identity',
        title: 'invoices.payout.table.id',
        width: 135,
        render: (_, record) => <IdentityCell record={record} />,
    },
    {
        dataIndex: 'userId',
        key: 'userId',
        title: 'invoices.payout.table.userId',
        width: 125,
        render: (_, record) => <UserIdCell record={record} />,
    },

    {
        dataIndex: 'externalIdentity',
        key: 'externalIdentity',
        title: 'invoices.payout.table.externalIdentity',
        width: 170,
        render: (_, record) => <ExternalIdentityCell record={record} />,
    },

    {
        dataIndex: 'siteName',
        key: 'siteName',
        title: 'invoices.payout.table.siteName',
    },

    {
        dataIndex: 'methodCode',
        key: 'methodCode',
        title: 'invoices.payout.table.methodName',
    },

    {
        dataIndex: 'amount',
        key: 'amount',
        title: 'invoices.payout.table.amount',
    },
    {
        dataIndex: 'currency',
        key: 'currency',
        title: 'invoices.payout.table.currency',
    },
    {
        dataIndex: 'merchantCommissionAmount',
        key: 'amount',
        title: 'invoices.payout.table.commission',
        render: (_, record) =>
            record.merchantCommissionAmount?.amount &&
            record.merchantCommissionAmount?.currency && (
                <CommissionCell
                    amount={record.merchantCommissionAmount?.amount}
                    currency={record.merchantCommissionAmount?.currency}
                />
            ),
    },
    {
        dataIndex: 'status',
        key: 'status',
        title: 'invoices.payout.table.status',
        render: (_, record) => <Status status={record.status} />,
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'invoices.payout.table.createdAt',
        render: (_, record) =>
            record.createdAt
                ? dateHelpers.formatToLocalTimezone(
                      record.createdAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
    {
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        title: 'invoices.payout.table.finishedAt',
        render: (_, record) =>
            record.finishedAt
                ? dateHelpers.formatToLocalTimezone(
                      record.finishedAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
];
